<template>
  <div class="">
    <button
      type="button"
      class="btn btn-warning"
      @click="restartDevice">
      Test Connection
    </button>

    <div v-if="isLoaded" class="mt-3">
      [ STATUS ]

      <div v-if="errorMessage">
        {{errorMessage}}
      </div>

      <ul class="list-unstyled" v-else>
        <li>Host: {{status.host}}</li>
        <li>Port: {{status.port}}</li>
      </ul>
    </div>
  </div>
</template>

<script>
import {
  DETAIL_STATUS,
  CHECK_DEVICE
} from './graph'

export default {
  props: {
    deviceId: {
      type: Number,
      required: true
    },
    deviceType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
  },
  data () {
    return {
      isLoaded: false,
      errorMessage: '',
      status: {
        host: null,
        port: null,
      }
    }
  },
  methods: {
    fetchData () {
      this.isLoaded = false
      this.$apollo.query({
        query: DETAIL_STATUS(this.templateType),
        variables: {
          deviceType: this.deviceType,
          deviceId: this.deviceId
        },
        fetchPolicy: 'no-cache'
      }).then(res => {
        const status = res.data.status
        Object.keys(this.status).forEach(key => {
          this.status[key] = status[key]
        })
      }).catch(() => {
        this.errorMessage = 'ไม่สามารถเชื่อมต่อได้'
      }).finally(() => {
        this.isLoaded = true
      })
    },
    restartDevice () {
      this.$apollo.mutate({
        mutation: CHECK_DEVICE(this.templateType),
        variables: {
          deviceType: this.deviceType,
          deviceId: this.deviceId,
        }
      }).then(() => {
        this.$toasted.global.success('ส่งคำสั่งไปยังเครื่องพิมพ์')
      }).catch(err => {
        this.$toasted.global.error(err)
      })
    }
  },
  created () {
    this.fetchData()
  }
}
</script>

<style lang="css" scoped>
</style>
